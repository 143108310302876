
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Col,  Button } from "reactstrap";
import Loader from "react-loaders";

import config from "../../config.json";
import logo from '../../assets/utils/images/e-proeri.jpg'
import background from '../Account/assets/Foto1.jpg'

class Activation extends Component {

    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
      this.state = {
        activationMessage : "",
        loading : true,
        ok: false
    };
}


  componentDidMount(){

    const activateAccountLink = process.env.REACT_APP_API + config.API_PUBLIC_VALIDATE;
    
    const params = new URLSearchParams(window.location.search);


        const token = params.get('token');

        if(token){
            axios.post(activateAccountLink, {
                Token : token 
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({activationMessage : "Su cuenta fue activada exitosamente", loading: false, ok: true});
                }else{
                    this.setState({activationMessage : resp.data.error || "Error", loading: false});
                }
    
            }).catch(err=>{
                this.setState({activationMessage : "Error de petición o token inválido", loading: false});
            });
        }

  }

  navigate(){
    //console.log(this.props);
    //this.props.history.push("/login");
    window.location.href = '/login';
  }

  render (){


    /*
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #A7FCA2;
    */

    return <Fragment>
    <div  style={{backgroundImage : `url('${background}')`,  
                        backgroundSize: "cover", // La imagen cubrirá el contenedor sin deformarse
                        backgroundPosition: "center", // Centra la imagen en el contenedor
                        backgroundRepeat: "no-repeat", // Evita que la imagen se repita
                        width: "100vw", // Ancho igual al ancho de la ventana
                        height: "100vh",}}
                        >
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
         
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
                  <div style={{width: '100%',display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                    <img src={logo} style={{width: 200, height: 70}}></img>
                  </div>
              </div>
              <div className="modal-body">
        
                     {this.state.loading ?  <div className="loader-wrapper d-flex justify-content-center align-items-center">
                        <Loader type="ball-scale-multiple" />
                      </div> : this.state.ok ? <div>
                          <span style={{textAlign: 'center'}}><b>¡Felicidades!</b></span>
                          <br/>
                          <span style={{textAlign: 'center'}}>
                          Tu cuenta ha sido activada exitosamente. Ya puedes iniciar sesión y acceder a todas las funcionalidades de la plataforma.
                          </span>
                      </div> : <h1 className="h1">{this.state.activationMessage}</h1>}

                    </div>
                        <div className="modal-footer clearfix">
                          <div className="float-right">
                             {this.state.ok &&  <Button color="primary" size="lg" onClick={this.navigate}>Ir a Login </Button> }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Fragment>
  }

};

export default withRouter(Activation);