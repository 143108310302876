import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

//const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Applications = lazy(() => import("../../DemoPages/Applications"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

const loaderContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Para que ocupe toda la altura de la ventana
    width: '100vw',  // Para que ocupe toda la anchura de la ventana
  };

const GeneralLoader = <div style={loaderContainerStyle}>
<Loader type="ball-clip-rotate-multiple" active />
<h1>Cargando...</h1>
</div>

const AppMain = (params) => {   

    return (       
        <Fragment>
            {/* Applications */}
            <Suspense fallback={GeneralLoader}>
                <Route path="/apps" component={Applications}/>
            </Suspense>

            {/* Dashboards */}
            
            <Suspense fallback={GeneralLoader}>
                <Route path="/dashboards" component={Dashboards }/>
            </Suspense>
            

            <Route exact path="/" render={() => (
                params.queryIdProcedurement == '' ?
                <Redirect to="/apps/represent"/> :
                <Redirect to={"/apps/procurement" + params.queryIdProcedurement }/>
            )}/>

            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
