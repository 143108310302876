import React, { Component, Fragment } from 'react';

import axios from "axios";
import { withRouter } from "react-router-dom";
import { DropdownList } from "react-widgets";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, InputGroup} from "reactstrap";
import Swal from 'sweetalert';
import platform from 'platform';

import { ValidateEmail, ValidatePassword} from '../../services/validationsService';
//import countries from "./assets/countries.json";
import config from '../../config.json';
import './assets/register.css';

const NEW_SUPPLIER = {
  "CompanyName": null,
  "TaxId": null,
  "Country": null,


  "ContactName": null,
  "ContactLastName": null,
  "ContactPosition": null,
  "ContactEmail": null,
  "Password": null,
  "Password_Confirm" : '', 
}


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newSupplier: {...NEW_SUPPLIER},
      selectedServices : [],
      servicesTypes : [],
      loadingRegister: false,
      showConfirm: false,
      showPass: false,
      doYouAgree : false,
      showConductModal: false,
      patternErrorMessages : {
        passwordLenght: "",
        notEqualPassword : "",
        emailPattern : "",
        existedEmail: "",
        existedCompanyName : "",
        existedTaxId : ""
      },
      countries : []
    };

    this.EmptyValues = {

    };


    this.handleInputChange = this.handleInputChange.bind(this);
    //this.handleInputUnfocus = this.handleInputUnfocus.bind(this);
    this.handlePatternErrors = this.handlePatternErrors.bind(this);
 

    this.hasPatternErrors = this.hasPatternErrors.bind(this);
    this.onClickRegisterNewSupplier = this.onClickRegisterNewSupplier.bind(this); 
    this.isDataValidAndComplete = this.isDataValidAndComplete.bind(this);
    this.handleConductModal = this.handleConductModal.bind(this);
    this.registerLogTermnsAndConditionsAccept = this.registerLogTermnsAndConditionsAccept.bind(this);
    this.registerLogUserSupplierRegister = this.registerLogUserSupplierRegister.bind(this);
    this.handleAccepTermns = this.handleAccepTermns.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cleanData = this.cleanData.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.handleCheckListServices = this.handleCheckListServices.bind(this);
    this.getServicesTypes = this.getServicesTypes.bind(this);
  }

  componentDidMount() {
    this.getCountries();
    this.getServicesTypes();
  }

  getCountries(){
    let url = process.env.REACT_APP_API + config.API_GENERAL_SUPPLIER + "/countries";
    axios.get(url).then(resp=>{
         if(resp.data){
            this.setState({countries : resp.data});
         }
    })
  }


  handleAccepTermns(event){
    const {checked} = event.target;
  
    this.setState({doYouAgree : checked});

     if(checked){
       this.registerLogTermnsAndConditionsAccept();
    } 

  }

  //Registro de proveedor
  handleInputChange = (event) => {
    const {value, name} = event.target;    

    let IfAnyError = {};
    let errors = this.handlePatternErrors({value, name});

    if(value === null || value === undefined || value === "" || this.hasPatternErrors()){
      IfAnyError.doYouAgree = false;  
    }


    this.setState(prevState=>({
      ...prevState,
      ...IfAnyError,
      newSupplier: {
        ...prevState.newSupplier,
        [name] : value
      },
      patternErrorMessages : {
        ...prevState.patternErrorMessages,
        ...errors
      }
      
    })); 

  }

  getServicesTypes(){

    const finalUrl = process.env.REACT_APP_API + config.API_GENERAL_SUPPLIER + `/getServicesTypes`;
      axios.get(finalUrl).then(resp=>{
        if(resp.data){
            this.setState({
              servicesTypes : resp.data
            })
        }
      })
  }

  handleCheckListServices(id, checked){
    let newList = [];

    if(checked){
       newList = [...this.state.selectedServices, id];
    }else{
      newList = this.state.selectedServices.filter(s=>s !== id);
    }


    this.setState(prev=>({
      ...prev,
      selectedServices : newList,
      servicesTypes :  prev.servicesTypes.map(s=>{
              if(s.id === id){
                  return {
                      ...s,
                      isSelected : checked
                  };
              }else{
                  return s;
              }
          })
  }), ()=>{
     console.log(this.state.selectedServices);
  });

  }

  handlePatternErrors = (event)=>{
       const {name, value} = event;    

       let errors = {}

       let errorMessage = "";
       let errorMessageName = "";

       if(name === "Password"){
         errorMessageName = "passwordLenght";

         const validationPassResult = ValidatePassword(value);
         
         if(!validationPassResult.IsValid){
           errorMessage = validationPassResult.MessageIfNotValid;
         }else{
          errorMessage = "";
         }

         errors[errorMessageName] = errorMessage;
       }


        if(name === "Password_Confirm"){
          errorMessageName = "notEqualPassword";
          if(this.state.newSupplier.Password !== value){
            errorMessage = "Contraseñas no coinciden";
          }else{
            errorMessage = "";
          }
          errors[errorMessageName] = errorMessage;
      }

      
      if(name === "ContactEmail"){  
        errorMessageName = "emailPattern";
      
          const validationEmailResult = ValidateEmail(value);

          if(!validationEmailResult.IsValid){
            errorMessage = validationEmailResult.MessageIfNotValid;
          }else{
            errorMessage = "";
          }
          errors[errorMessageName] = errorMessage;
          errors["existedEmail"] = "";
      }

      if(name === "CompanyName"){
        errorMessageName = "existedCompanyName";

         if(!value){
            errorMessage = "Dato requerido";
         }else{
            errorMessage = ""
         }
         errors[errorMessageName] = errorMessage;
      }

      if(name === "TaxId"){
        errorMessageName = "existedTaxId";

        if(!value){
           errorMessage = "Dato requerido";
        }else{
           errorMessage = ""
        }
        errors[errorMessageName] = errorMessage;
     }

      return errors;
  }

  hasPatternErrors(){
    return Object.values(this.state.patternErrorMessages).some(err=>err !== "");
  }

  async registerLogUserSupplierRegister(idUser){
    try{

      
      const finalUrl = process.env.REACT_APP_API + config.API_LOGS + `/insertLogUserSupplierRegister`;

      const respIp = await axios.get('https://api.ipify.org?format=json');

      const IP = respIp.data.ip || null;

      await axios.post(finalUrl, {
          UserId : idUser,
          Browser : platform.name,
          SourceUrl : '/registerWithModal',
          PageName : 'Register User (Modal)',
          Ip : IP
      },
      {
          headers: {
              'Content-Type': 'application/json'
          }
      }
  ) 

  }catch(e){
      console.log("Navigation Register Error:");
      console.log(e);
  }
  }

  async registerLogTermnsAndConditionsAccept(){

    try{
      const finalUrl = process.env.REACT_APP_API + config.API_LOGS + `/insertLogTermnsAccept`;

      const respIp = await axios.get('https://api.ipify.org?format=json');

      const IP = respIp.data.ip || null;

      await axios.post(finalUrl, {
          UserId : null,
          Browser : platform.name,
          SourceUrl : '/registerWithModal',
          PageName : 'Register User Modal (Accept Termns and conditions)',
          Ip : IP
      },
      {
          headers: {
              'Content-Type': 'application/json'
          }
      }
  ) 

  }catch(e){
      console.log("Navigation Register Error:");
      console.log(e);
  }
  }


  onClickRegisterNewSupplier(){
    let dataNewSupplier = {...NEW_SUPPLIER};
   

    try {
      this.setState({
        loadingRegister: true
      })
     
      if(this.hasPatternErrors()){
        console.log('Has pattern errors');
        this.setState({
          loadingRegister: false
        })
        return;
      }

     

      if(!this.isDataValidAndComplete()){ 
        const emptyProps = Object.values(this.EmptyValues).join(', ');
       
        Swal('Advertencia', 'Por favor complete los campos: ' + emptyProps, 'warning');
        this.setState({
          loadingRegister: false
        })
        return;
      }
  
      let url = process.env.REACT_APP_API + config.API_AUTH_MANUAL;
  
      dataNewSupplier.CompanyName = this.state.newSupplier.CompanyName;
      dataNewSupplier.TaxId = this.state.newSupplier.TaxId !== null || this.state.newSupplier.TaxId !== undefined ?  this.state.newSupplier.TaxId : "";
      dataNewSupplier.Country = this.state.newSupplier.Country.name;
  
      dataNewSupplier.ContactName = this.state.newSupplier.ContactName;
      dataNewSupplier.ContactLastName = this.state.newSupplier.ContactLastName;
      dataNewSupplier.ContactPosition = this.state.newSupplier.ContactPosition;
      dataNewSupplier.ContactEmail = this.state.newSupplier.ContactEmail;
      dataNewSupplier.Password = this.state.newSupplier.Password;
      dataNewSupplier.ServiceTypes = this.state.selectedServices;
      
     
      axios.put(url , dataNewSupplier)
        .then((response) => {  
         
          if(response.data.operacionConExito){
            //Swal('Usuario Registrado', 'Tu cuenta ha sido creada con éxito. Por favor, revisa tu correo electrónico y sigue las instrucciones para activar tu cuenta. Si no recibes el correo en los próximos minutos, verifica tu carpeta de spam o contacta al soporte técnico.', 'success');
            Swal({
              title: "¡Usuario Registrado!",
              content: {
                element: "span",
                attributes: {
                  innerHTML: "Tu cuenta ha sido creada con éxito. Por favor, <strong>revisa tu correo electrónico</strong> y sigue las instrucciones para activar tu cuenta. Si no recibes el correo en los próximos minutos, verifica tu carpeta de spam o contacta al soporte técnico."
                },
              },
              icon: "success"
            });

            this.handleClose();
            this.registerLogUserSupplierRegister(response.data.id);


            setTimeout(()=>{
              Swal.close();
            }, 30000)

          }else{

            const errors = {};
           

            if(response.data.errors && response.data.errors.length){
              response.data.errors.forEach(e=>{
                  errors[e.errorName] = e.error;
              });
            }

            this.setState((prevState)=>({
              ...prevState,
              patternErrorMessages : {
                ...prevState.patternErrorMessages,
                ...errors
              },
              loadingRegister: false
              }) 
            );
          }
   
  
        }).catch((error) => {
          console.log(error);
          this.setState({loadingRegister : false});
        })     
      
    } catch (error) {
        console.log(error);
        this.setState({loadingRegister : false});
    }       
  }

  isDataValidAndComplete(){
    if(this.state.newSupplier.CompanyName === null || this.state.newSupplier.CompanyName === undefined || this.state.newSupplier.CompanyName === ""){
      this.EmptyValues["CompanyName"] = "Nombre de la entidad";
      return false;
    }else{
      this.EmptyValues["CompanyName"] = "";
    }

    if(this.state.newSupplier.TaxId === null || this.state.newSupplier.TaxId === undefined || this.state.newSupplier.TaxId === ""){
      this.EmptyValues["TaxId"] = "Registro único tributario o comercial";
      return false;
    }else{
      this.EmptyValues["TaxId"] = "";
    }

    if(this.state.newSupplier.Country === null || this.state.newSupplier.Country === undefined || this.state.newSupplier.Country === ""){
      this.EmptyValues["Country"] = "País";
      return false; 
    }else{
      this.EmptyValues["Country"] = "";
    }

    if(this.state.newSupplier.ContactName === null || this.state.newSupplier.ContactName === undefined || this.state.newSupplier.ContactName === ""){
      this.EmptyValues["ContactName"] = "Nombre(s) del Representante Legal";
      return false;
    }else{
      this.EmptyValues["ContactName"] = "";
    }

    if(this.state.newSupplier.ContactLastName === null || this.state.newSupplier.ContactLastName === undefined || this.state.newSupplier.ContactLastName === ""){
      this.EmptyValues["ContactLastName"] = "Apellidos del Representante Legal";
      return false;
    }else{
      this.EmptyValues["ContactLastName"] = "";
    }

    if(this.state.newSupplier.ContactPosition === null || this.state.newSupplier.ContactPosition === undefined || this.state.newSupplier.ContactPosition === ""){
      this.EmptyValues["ContactPosition"] = "Posición";
      return false;
    }else{
      this.EmptyValues["ContactPosition"] = "";
    }

    if(this.state.newSupplier.ContactEmail === null || this.state.newSupplier.ContactEmail === undefined || this.state.newSupplier.ContactEmail === ""){
      this.EmptyValues["ContactEmail"] = "Correo electrónico"; 
      return false;
    }else{
      this.EmptyValues["ContactEmail"] = "";
    }

    if(this.state.newSupplier.Password === null || this.state.newSupplier.Password === undefined || this.state.newSupplier.Password === ""){
      this.EmptyValues["Password"] = "Contraseña";  
      return false;
    }else{
      this.EmptyValues["Password"] = "";  
    }

    if(this.state.newSupplier.Password !== this.state.newSupplier.Password_Confirm){
      return false;
    }

    
    return true;

  }

  handleConductModal(e){

    e.preventDefault();

    this.setState({
       showConductModal : true
    })

  }


  handleClose(){
    this.props.toggle();
    this.cleanData();
  }

  cleanData(){
    this.setState({
      newSupplier: {...NEW_SUPPLIER},
      loadingRegister : false,
      showConfirm: false,
      showPass: false,
      doYouAgree : false,
      showConductModal: false,
      patternErrorMessages : {
        passwordLenght: "",
        notEqualPassword : "",
        emailPattern : "",
        existedEmail: "",
        existedCompanyName : "",
        existedTaxId : ""
      }
     });
  }


  render() {

    const IsTerminsCheckDisabled = !this.isDataValidAndComplete() || this.hasPatternErrors() || this.state.selectedServices.length === 0;
    const IsRegisterButtonDisabled = this.state.loadingRegister || !this.state.doYouAgree || IsTerminsCheckDisabled;
   
    return  (
        <Fragment>
        <Modal isOpen={this.props.showModal} toggle={this.props.toggle} size="lg" backdrop="static" keyboard={false} >
          <ModalHeader >Datos del proveedor</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="CompanyName">Nombre de la entidad</Label>
                  <Input type="text" name="CompanyName" id="CompanyName" placeholder="Entidad" onChange={this.handleInputChange} />
                   {this.state.patternErrorMessages.existedCompanyName !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.existedCompanyName}</span>} 
                </FormGroup> 
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="TaxId">Registro único tributario o comercial</Label>
                  <Input type="text" name="TaxId" id="TaxId" placeholder="Registro único tributario o comercial" onChange={this.handleInputChange} />
                  {this.state.patternErrorMessages.existedTaxId !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.existedTaxId}</span>} 
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Country">País</Label>
                  <DropdownList data={this.state.countries}  value={this.state.newSupplier.Country} dataKey="name" textField="name" 
                            onChange={(value) => this.handleInputChange({target: { name: 'Country', value: value}})}/>
                </FormGroup>
              </Col>
              <Col md="6">
                
              </Col>  
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="ContactName">Nombre(s) del Representante Legal</Label>
                  <Input type="text" name="ContactName" id="ContactName" placeholder="Ingrese nombre(s)" onChange={this.handleInputChange}/>
                </FormGroup>
              </Col>
              <Col md="6">  
                <FormGroup>
                  <Label for="ContactLastName">Apellidos del Representante Legal</Label>
                  <Input type="text" name="ContactLastName" id="ContactLastName" placeholder="Ingrese apellidos" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="ContactPosition">Posición</Label>
                  <Input type="text" name="ContactPosition" id="ContactPosition" placeholder="Posición" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="ContactEmail">Correo electrónico</Label>
                  <Input type="email" name="ContactEmail" id="ContactEmail" placeholder="Ingrese correo electrónico" onChange={this.handleInputChange}/>
                  {this.state.patternErrorMessages.emailPattern !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.emailPattern}</span>}
                  {this.state.patternErrorMessages.existedEmail !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.existedEmail}</span>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Password">Contraseña</Label>
                  <InputGroup>
                    <Input type={!this.state.showPass ? 'password' : 'text'} name="Password" id="Password" placeholder="Ingrese su contraseña" onChange={this.handleInputChange}  />
                    <Button color={!this.state.showPass ? 'info' : 'danger'} onClick={()=>this.setState({showPass: !this.state.showPass})}>
                        {!this.state.showPass ?  <i className='pe-7s-look'></i> : <i className='pe-7s-lock'></i>}
                    </Button>
                  </InputGroup>
                  {this.state.patternErrorMessages.passwordLenght !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.passwordLenght}</span>}
                </FormGroup>
              </Col>
              <Col md="6">
              <FormGroup>
                  <Label for="Password_Confirm">Confirme su contraseña</Label>
                  <InputGroup>
                    <Input type={!this.state.showConfirm ? 'password' : 'text'} name="Password_Confirm" value={this.state.Password_Confirm} id="Password_Confirm" placeholder="Confirme su contraseña" onChange={this.handleInputChange} />
                    <Button color={!this.state.showConfirm ? 'info' : 'danger'} onClick={()=>this.setState({showConfirm: !this.state.showConfirm})}>
                        {!this.state.showConfirm ?  <i className='pe-7s-look'></i> : <i className='pe-7s-lock'></i>}
                    </Button>
                  </InputGroup>
                  {this.state.patternErrorMessages.notEqualPassword !== "" && <span style={{color: 'red'}}>{this.state.patternErrorMessages.notEqualPassword}</span>}
              </FormGroup>
              </Col>
            </Row>
            <br/>
            <h5>Areas de Servicio <span style={{color: 'red'}}>*</span>: </h5>
            <p>*Recibirá notificaciones de publicaciones de procesos en las áreas seleccionadas</p>
            <Row>
                  <Col md="6">
                      {
                          this.state.servicesTypes.map((s, i)=>{

                                  if(i <= 3){
                                      return <Fragment key={s.id}>
                                              <Row>
                                                  <Col md="2">
                                                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                      <Input
                                                              type="switch"
                                                              checked={s.isSelected}
                                                              onClick={() =>this.handleCheckListServices(s.id, !s.isSelected)}
                                                          />
                                                  </div>
                                                  </Col>
                                                  <Col md="10">
                                                      <Label>{s.serviceType}</Label>
                                                  </Col>
                                              </Row> 
                                              <br/>
                                      </Fragment>    
                                  }else{
                                    return null;
                                  }

                          })
                      }
                  </Col>
                  <Col md="6">
                      {
                          this.state.servicesTypes.map((s, i)=>{

                                   if(i >= 4){
                                      return <Fragment key={s.id}>
                                              <Row>
                                                  <Col md="2">
                                                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                      <Input
                                                              type="switch"
                                                              checked={s.isSelected}
                                                              onClick={() =>this.handleCheckListServices(s.id, !s.isSelected)}
                                                          />
                                                  </div>
                                                  </Col>
                                                  <Col md="10">
                                                      <Label>{s.serviceType}</Label>
                                                  </Col>
                                              </Row> 
                                              <br/>
                                      </Fragment>    
                                   }else{
                                    return null;
                                   }

                          })
                      }
                  </Col>
              </Row>
            <br/>
            <Row>
              <Col md="1">
                <div style={{display: 'flex', justifyContent: 'flex-end'}}> 
                  <Input type="checkbox" disabled={IsTerminsCheckDisabled} checked={this.state.doYouAgree} onChange={this.handleAccepTermns} />
                </div>
              </Col>
              <Col md="11">
                  <h6 style={{textAlign: 'justify'}}>
                       He leído y acepto el <strong style={{ color: '#249fcd'}}  className="underline-link"><a  onClick={this.handleConductModal}>Código de Conducta</a> </strong>para el uso del sistema E-PROERI como los estándares mínimos esperados de todos los usuarios y participantes en los procesos de licitación gestionados a través de esta plataforma.
                  </h6>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={IsRegisterButtonDisabled} onClick={this.onClickRegisterNewSupplier} >
              Registrarse
            </Button>{' '}
            <Button color="secondary" onClick={this.handleClose} >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showConductModal} toggle={()=>this.setState({showConductModal : !this.state.showConductModal})} size="lg" >
          <ModalHeader><h2>Código de Conducta para la Utilización del Sistema E-PROERI</h2></ModalHeader>
          <ModalBody>
              <div style={{textAlign: 'justify', fontSize: '1.05em'}}>
                  El uso del sistema E-PROERI debe alinearse con los principios de transparencia, integridad y responsabilidad, garantizando que todas las actividades relacionadas con el Programa de Emergencia para la Reconstrucción Integral y Resiliente de Infraestructura (PROERI) se lleven a cabo de manera ética y conforme a los más altos estándares de profesionalismo. Por lo tanto, los usuarios del sistema se comprometen a lo siguiente:
              </div>
              <br/>
              <ol class="custom-list">
                <li><span className='subtitle_conduct'>Uso Apropiado del Sistema:</span>
                    <ul>
                        <li><span className='text_subtitle'>El sistema E-PROERI debe ser utilizado únicamente para los fines para los cuales ha sido diseñado, específicamente la gestión, monitoreo y comunicación de los procesos de licitación relacionados con el PROERI.</span></li>
                        <li><span className='text_subtitle'>Todos los usuarios deben asegurarse de proporcionar información verídica y precisa dentro del sistema, evitando la falsificación, omisión o manipulación de datos.</span></li>
                    </ul>
                </li>
                <li><span className='subtitle_conduct'>Confidencialidad y Protección de Datos:</span>
                    <ul>
                        <li><span className='text_subtitle'>Los usuarios tienen la responsabilidad de proteger la confidencialidad de la información sensible a la que acceden a través del sistema. Está prohibido compartir información interna de licitaciones con personas no autorizadas o utilizarla para fines ajenos al programa PROERI.</span></li>
                        <li><span className='text_subtitle'>Se debe cumplir con todas las normativas y regulaciones vigentes en materia de protección de datos personales y seguridad de la información.</span></li>
                    </ul>
                </li>
                <li><span className='subtitle_conduct'>Transparencia y Ética:</span>
                    <ul>
                        <li><span className='text_subtitle'>Los procesos gestionados a través del sistema E-PROERI deben ser transparentes, promoviendo una competencia justa y equitativa entre los proveedores participantes.</span></li>
                        <li><span className='text_subtitle'>Se prohíbe el uso del sistema para cualquier actividad que implique conflicto de intereses, corrupción, soborno o cualquier otra conducta antiética que ponga en riesgo la integridad del programa y las instituciones participantes.</span></li>
                    </ul>
                </li>
                <li><span className='subtitle_conduct'>Responsabilidad en la Toma de Decisiones:</span>
                    <ul>
                        <li><span className='text_subtitle'>Todos los usuarios con capacidad de decisión dentro del sistema deben actuar de manera imparcial, asegurando que las decisiones se basen en criterios objetivos y claros, siempre en beneficio del programa y los ciudadanos afectados.</span></li>
                        <li><span className='text_subtitle'>Las acciones realizadas dentro del sistema están sujetas a auditoría y monitoreo. El incumplimiento de este código de conducta podrá resultar en sanciones disciplinarias, incluyendo la pérdida de acceso al sistema.</span></li>
                    </ul>
                </li>
                <li><span className='subtitle_conduct'>Seguridad del Sistema:</span>
                    <ul>
                        <li><span className='text_subtitle'>Los usuarios deben seguir las mejores prácticas de ciberseguridad, incluyendo el uso de contraseñas seguras y la protección de sus credenciales de acceso. Está prohibido compartir credenciales con terceros.</span></li>
                        <li><span className='text_subtitle'>Cualquier incidente de seguridad o mal uso del sistema debe ser reportado de inmediato a los administradores de E-PROERI para su pronta resolución.</span></li>
                    </ul>
                </li>
              </ol>   
              <br/>
              <div style={{textAlign: 'justify', fontSize: '1.05em'}}>
              Al utilizar el sistema E-PROERI, cada usuario se compromete a cumplir con todo lo anteriormente descrito en este Código de Conducta. Cualquier incumplimiento de estas normas o la participación en prácticas no éticas, como corrupción, manipulación de datos o uso indebido de la información, será motivo de sanciones, que pueden incluir la suspensión o cancelación del acceso al sistema, así como acciones legales correspondientes, según la gravedad de la falta.
              </div>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={()=>this.setState({showConductModal : !this.state.showConductModal})} >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
      );
  }
};


export default withRouter(Login);
