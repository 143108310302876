import React, { Component, Fragment } from 'react';
//import { GoogleOAuthProvider } from '@react-oauth/google';
//import { GoogleLogin } from '@react-oauth/google';
//import { GoogleLogin } from 'react-google-login';
//import { FcGoogle } from 'react-icons/fc';
import axios from "axios";
import platform from "platform"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, InputGroup, InputGroupText } from "reactstrap";
import Swal from 'sweetalert';
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
//import { DropdownList } from "react-widgets";


import { login } from "../../actions/authActions";
import config from '../../config.json';
//import { gapi } from "gapi-script";
//import countries from "./assets/world.json";

import RegisterView from './RegisterView';


import bg1 from "./assets/Foto1.jpg";
import bg2 from "./assets/Foto2.jpg";
import logo from "../../assets/utils/images/e-proeri.jpg";
import { ValidateEmail } from '../../services/validationsService';


const AUTH_MANUAL = {
  "Email": null,
  "Pass": null,
  "Log" : {
      UserId : null,
      Browser : null,
      SourceUrl : null,
      PageName : null,
      Ip : null
  }
}


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      auth_manual: AUTH_MANUAL,
      isResetPassModelVisible: false,
      emailReset: "",
      emailPatternResetError: "",
      notExistingResetEmail: "",
     
      isLoginPassVisible : false
    };

  /*   window.gapi.load("auth2", () => {
      window.gapi.auth2.init({
        client_id: config.GOOGLE_CLIENT_ID,
        scope: "email",
      })
    }) */

    this.toggle = this.toggle.bind(this);
    this.handleInputChangeLogin = this.handleInputChangeLogin.bind(this); 
    this.onClickPasswordReset = this.onClickPasswordReset.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.sendTokenRequestForEmail = this.sendTokenRequestForEmail.bind(this);

    this.onClickLoginManual = this.onClickLoginManual.bind(this); 
  }

  componentDidMount() {
  }

  onFailure = (error) => {
    alert(error);
  };

  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  googleResponse = (response) => {
    if (!response.tokenId) {
      console.error("Unable to get tokenId from Google", response)
      return;
    }

    const bod = { 'tokenId': response.tokenId }
    const values = JSON.stringify(bod);

    const options = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
      /*mode: 'cors',
      cache: 'default'*/
    };
    let url = process.env.REACT_APP_API + config.GOOGLE_AUTH_CALLBACK_URL;
    fetch(url, options)
      .then(r => {
        r.json().then(user => {
          const token = user.token;
          if(user.autenticado ===1)          
          {
            this.props.login(user);
            var data = {id:3,url:""};
            this.props.history.push({
                pathname: '/',
                query:data,
              }) 
          }
          else
          {
          this.renderModal(user.error);            
          }
        });
      })
  };


  renderModal(mensaje) {
    this.mensajeLogin = mensaje;
  }




  //Login
  handleInputChangeLogin = (event) => {
    const target = event.target;    

    let auth_manual = this.state.auth_manual;
    auth_manual[target.name] = target.value;
    
    this.setState({
      auth_manual: auth_manual
    }); 
  }

  handlePasswordReset = (event)=>{

      const val = event.target.value;

      let errorMessage = "";

      const validationResult = ValidateEmail(val);

        if(!validationResult.IsValid){
          errorMessage = validationResult.MessageIfNotValid;
        }else{
          errorMessage = "";
        }

        this.setState({
           emailReset : val,
           emailPatternResetError : errorMessage
        })

  }


  sendTokenRequestForEmail(){

    

    let url = process.env.REACT_APP_API + config.API_AUTH_MANUAL + "/sendResetPasswordToken";
    axios.post(url, 
       JSON.stringify(this.state.emailReset),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {  
      
      if(response.data.operacionConExito){
        Swal('Petición Exitosa', 'Se ha enviado un correo para verificación y cambio de contraseña', 'success');
        this.setState({ isResetPassModelVisible : false });
      }else{

        this.setState({
           emailPatternResetError : response.data.error
        })
      }



    }).catch((error) => {
      console.log(error);
      //this.setState({showModal : false});
    })    

  }


  async onClickLoginManual(){     
    let data = AUTH_MANUAL;
 /*    let logExternal = {
      UserId : null,
      Browser : platform.name,
      SourceUrl : "/Login",
      PageName : "Login",
    } */

    data.Email = this.state.auth_manual.Email;
    data.Pass = this.state.auth_manual.Pass;
    //Data for logs
    data.Log.Browser = platform.name;
    data.Log.SourceUrl = "/Login";
    data.Log.PageName = "Login";  

    const respIp = await axios.get('https://api.ipify.org?format=json');
    const IP = respIp.data.ip || null;

    data.Log.Ip = IP;

    let url = process.env.REACT_APP_API + config.API_AUTH_MANUAL;
  
    
    axios.post(url, data)
      .then((response) => {  
        //console.log(response);
        if(response.data.autenticado === 1)
        {
          this.props.login(response.data);          
          this.props.history.push({
              pathname: '/',              
              search: this.props.location.search
            }) 
        }
        else
        {
          this.renderModal(response.data.error);            
          Swal('Advertencia', response.data.error, 'warning');
        }
      }).catch((error) => {
        console.log(error);
      }); 
  }

  onClickPasswordReset(){
    
     this.setState({
        isResetPassModelVisible: true
     })
  }


  



  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    
    let content = !!this.props.auth.isAuthenticated ?
      (
        <div>
          <Redirect to={{
            pathname: '/'
          }} />
        </div>
      ) :
      (
        <Fragment>
        <div className="h-100">
          <Row className="h-100 g-0">
          <Col lg="4" md="12" sm="12" className="h-100 d-flex bg-white align-items-center">
              <Col xl="9" lg="9" md="7" sm="8" className="app-login-box mx-auto">
                <div >
                  <div className='text-center' style={{ width: '270px', margin:'auto'}}>
                    <img src={logo} alt="react logo" style={{ width: '270px', }}/>
                  </div>       
                  <div className="divider"></div>
                  <h4 className='text-danger text-center'><b>AVISO DE USO EXCLUSIVO</b></h4>
                  <div className='text-center mt-4' style={{width: 360, margin: 'auto'}}>
                    <span>
                        Este sistema es de uso exclusivo para <span className='text-primary'><b>proveedores </b></span>
                        que participen en licitaciones del proyecto <span className='text-primary'><b>PROERI.{" "}</b></span>
                        El acceso no autorizado está prohibido y puede 
                        estar sujeto a acciones legales.
                    </span>
                    </div>         
                  <div style={{ width: '270px', margin:'auto', paddingTop:'20px', textAlign:'center'}}>
                    <div className="divider"></div>
                    {/* <Row>
                      <Col md="12">
                        <GoogleLogin
                            //color="primary"
                            scope= 'profile email'
                            clientId={config.GOOGLE_CLIENT_ID}
                            buttonText="Inicia sesión con Google"
                            onSuccess={this.googleResponse}
                            onFailure={this.googleResponse}
                            theme= 'dark'
                            width= '240'
                            height= '50'
                            longtitle= 'true'
                            cookiePolicy={"single_host_origin"}
                            //scope="profile"            
                          />
                      </Col>
                    </Row>                          
                    <div className="divider"></div> */}
                    <Row className="text-center">
                      <Col md="12">                        
                        <FormGroup>                
                        <InputGroup>
                              <Input type="email" name="Email" id="Email" placeholder="Ingrese su correo" onChange={this.handleInputChangeLogin} />
                              <InputGroupText>
                                    <FontAwesomeIcon icon={faEnvelope} size="1x"/>
                              </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                 <Input type={this.state.isLoginPassVisible ? "text" : "password"} name="Pass" id="Pass" placeholder="Ingrese su Contraseña" onChange={this.handleInputChangeLogin}/>
                                 <InputGroupText onClick={()=>this.setState({isLoginPassVisible : !this.state.isLoginPassVisible})}>
                                       <FontAwesomeIcon icon={this.state.isLoginPassVisible ? faEyeSlash : faEye} size="1x"/>
                                  </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Button style={{width : "100%"}} color="primary" onClick={this.onClickLoginManual} >Iniciar sesión</Button>
                        </FormGroup>                        
                        <FormGroup>

                        </FormGroup>

                      </Col>                      
                    </Row>
                    <Row>
                      <Col md="6">
                        <Button style={{width : "100%"}} onClick={this.onClickPasswordReset} color="link">Olvidé mi contraseña</Button>
                      </Col>
                      <Col md="6">
                        <Button style={{width : "100%"}} color="link" onClick={this.toggle} >Registrarse</Button>
                      </Col>
                    </Row>
                  
                  {/*}<GoogleOAuthProvider 
                      clientId={config.GOOGLE_CLIENT_ID}
        >{*/}
                   
                  {/*}</GoogleOAuthProvider>{*/}
                  
                  </div>
                </div>
              </Col>
            </Col>

            <Col lg="8" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}/>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}/>
                  </div>
                </Slider>
              </div>
            </Col>

          </Row>
        </div>
       
        <RegisterView showModal={this.state.showModal} toggle={this.toggle}/>

        <Modal isOpen={this.state.isResetPassModelVisible} toggle={()=>this.setState({isResetPassModelVisible : !this.state.isResetPassModelVisible})} size="md" >
          <ModalHeader >Olvidé mi Contraseña</ModalHeader>
          <ModalBody>
              <Row md="12">
                <Col>
                <Label for="Password_Confirm">Ingrese su correo electrónico: </Label>
                  <Input type="text" name="email_reset" id="Password_Reset" placeholder="Email" onChange={this.handlePasswordReset} />
                  {this.state.emailPatternResetError !== "" ? <span style={{color: 'red'}}>{this.state.emailPatternResetError}</span>
                  : (this.state.notExistingResetEmail !== "" &&  <span style={{color: 'red'}}>{this.state.notExistingResetEmail}</span>)  
                }
                </Col>
              </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={this.state.emailPatternResetError !== ""} onClick={this.sendTokenRequestForEmail}>
               Enviar Solicitud
            </Button>{' '}
            <Button color="secondary" onClick={()=>this.setState({isResetPassModelVisible : false})} >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
      );

    return (
      <div>
          {content}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (token) => {
      dispatch(login(token));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
