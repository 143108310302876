
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, FormGroup, Label, Input, InputGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from 'axios';
import Swal from 'sweetalert';


import config from '../../config.json';

import logo from '../../assets/utils/images/e-proeri.jpg'
import background from '../Account/assets/Foto1.jpg'
import successGif from '../Account/assets/icons8-check.gif'
import errorGif from '../Account/assets/icons8-error.gif'

import { ValidatePassword } from "../../services/validationsService";

class PasswordReset extends Component {

    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
      this.handlePaswordChange = this.handlePaswordChange.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.changePassword = this.changePassword.bind(this);

      this.state = {
        resultMessage : "",
        loading : false,
        ok: false,
        newPassword : "",
        newPasswordCopy : "",
        passwordLenghtError : "",
        notEqualPassword : "",
        showConfirm: false,
        showPass: false,
        showModal : false
    };
}

 componentDidMount(){
     
 }


  handlePaswordChange(event){

    const { name, value} = event.target;


    let errorMessageName = "";
    let errorMessage = "";

    if(name === "newPassword"){
        errorMessageName = "passwordLenghtError";

        const validationResult = ValidatePassword(value);

        if(!validationResult.IsValid){
          errorMessage = validationResult.MessageIfNotValid;
        }else{
         errorMessage = "";
        }
      }


       if(name === "newPasswordCopy"){
         errorMessageName = "notEqualPassword";
         if(this.state.newPassword !== value){
           errorMessage = "Contraseñas no coinciden";
         }else{
           errorMessage = "";
         }
     }

     this.setState({
        [errorMessageName] : errorMessage
     })
  }

  handleInputChange(event){

     const {name, value} = event.target;

     this.setState({
        [name] : value
     })

     this.handlePaswordChange(event);

  }


  changePassword(){


    if(this.state.passwordLenghtError !== "" || this.state.notEqualPassword !== ""){
        return;
    }

    this.setState({
        loading: true
    })
    
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if(token){

            const changePasswordEndpoint = process.env.REACT_APP_API + config.API_PUBLIC_RESET_PASS;
            
            axios.put(changePasswordEndpoint, 
                JSON.stringify(this.state.newPassword), 
                {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({resultMessage: 'Actualización correcta', loading: false, ok: true, showModal: true});
                }else{
                    this.setState({resultMessage : resp.data.error || "Error", loading: false, showModal : true});
                }
    
            }).catch(err=>{
                console.log(err);
                this.setState({resultMessage : "Error de petición o token inválido", loading: false, showModal : true});
            });
        }
  }

  navigate(){
    window.location.href = '/login';
  }

  render (){


    const IsInvalid = this.state.passwordLenghtError !== "" || this.state.notEqualPassword !== "";

    const finalGif = this.state.ok ? successGif : errorGif;
    const message = this.state.ok ? 'La contraseña ha sido actualizada correctamente' : this.state.resultMessage;

    return  <Fragment>
    <div style={{background : `url("${background}")`, backgroundSize: 'cover'}}>
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
         
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
               
                  <div style={{width: '100%',display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                    <img src={logo} style={{width: 200, height: 70}}></img>
                  </div>
                
              </div>
              <div className="modal-body">
                    <Row>
                        <FormGroup>  
                            <Label for="newPassword">Nueva Contraseña</Label>  
                            <InputGroup>
                              <Input type={!this.state.showPass ? 'password' : 'text'} name="newPassword" id="newPassword" placeholder="Ingrese contraseña" onChange={this.handleInputChange}  /* onBlur={this.handlePaswordChange}  *//>
                                <Button color={!this.state.showPass ? 'info' : 'danger'} onClick={()=>this.setState({showPass: !this.state.showPass})}>
                                    {!this.state.showPass ?  <i className='pe-7s-look'></i> : <i className='pe-7s-lock'></i>}
                                </Button>
                            </InputGroup>     

                            {this.state.passwordLenghtError !== "" && <span style={{color: 'red'}}>{this.state.passwordLenghtError}</span>}
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup>  
                            <Label for="newPasswordCopy">Ingrese nuevamente su Contraseña</Label>   
                            <InputGroup>
                               <Input type={!this.state.showConfirm ? 'password' : 'text'} name="newPasswordCopy" id="newPasswordCopy" placeholder="Confirme su contraseña" onChange={this.handleInputChange} /* onBlur={this.handlePaswordChange} */ />
                               <Button color={!this.state.showConfirm ? 'info' : 'danger'} onClick={()=>this.setState({showConfirm: !this.state.showConfirm})}>
                                  {!this.state.showConfirm ?  <i className='pe-7s-look'></i> : <i className='pe-7s-lock'></i>}
                              </Button>
                            </InputGroup>                   
                            {this.state.notEqualPassword !== "" && <span style={{color: 'red'}}>{this.state.notEqualPassword}</span>}
                        </FormGroup>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Button style={{width : "100%"}} className="btn btn-lg btn-info" disabled={IsInvalid || this.state.loading} onClick={this.changePassword} color="red">Cambiar Contraseña</Button>
                      </Col>
                    </Row>
            
                   {/*  <h5 className="h5">{this.state.resultMessage}</h5> */}
                    
                   </div>
                   {/*  <div className="modal-footer clearfix">
                      <div className="float-right">
                      {this.state.ok && <Button color="primary" size="lg"onClick={this.navigate}>Ir a Login </Button>}
                      </div>
                  </div> */}
            </div>
          </div>
        </Col>
      </div>
    </div>
    <Modal size="md" isOpen={this.state.showModal} toggle={()=>this.setState({showModal : !this.state.showModal})}>
          <ModalHeader toggle={()=>this.setState({showModal : !this.state.showModal})}></ModalHeader>
          <ModalBody>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                          <img src={finalGif} style={{height: 50, width: 50}}></img>
                          <br/>
                          <h3 style={{textAlign: 'center'}}>{message}</h3>
                          <br/>
                          {this.state.ok && <Button color="primary" size="lg"onClick={this.navigate}>Ir a Login </Button>}
                </div>
          </ModalBody>      
        </Modal>   
  </Fragment>
  }

};

export default withRouter(PasswordReset);



